<template>
  <div>
    <Alerts ref="alert"></Alerts>
    <div class="card border-info">
      <div class="card-header">
        <strong class="text-info">&Uacute;ltimos pagos (Facturación en línea)</strong>
        <div class=" card-header-actions">
          <form class="form-inline">
            <b>Filas por p&aacute;gina</b>
            <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                    v-model="filasPorPagina">
              <option v-for="option in sizeoptions" :value="option.value">
                {{ option.text }}
              </option>
            </select>
            <button class="btn btn-outline-dark mr-2" type="button"
                    @click="borrarFiltroBtnOnClick()">
              <font-awesome-icon icon="fa-sort-amount-up-alt"/>
              Quitar Filtro
            </button>
            <button class="btn btn-outline-success" data-html="true"
                    data-toggle="tooltip"
                    title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                    type="button"
                    @click="excelBtnOnClick()">
              <font-awesome-icon icon="fa-file-excel"/>
              Exportar a Excel
            </button>
          </form>
        </div>
      </div>
      <div class="card-body p-0 pe-1 pr-1">
        <JqxGrid :width="'100%'" ref="gridSystem"
                 :source="dataAdapter"
                 :autoheight="true" :autorowheight="false" :rowsheight="50"
                 :pageable="true"
                 :pagesize="parseInt(filasPorPagina)"
                 :virtualmode="true"
                 :rendergridrows="rendergridrows"
                 :columns="columns" :enablebrowserselection="true"
                 :enabletooltips="true" :filterable="true"
                 :showfilterrow="true"
                 :sortable="true"
                 :pagermode="'simple'" :localization="localization"
                 :theme="'bootstrap'" :columnsresize="true"/>
      </div>
    </div>
    <div class="card border-info">
      <div class="card-header">
        <strong class="text-primary">Pagos del sistema antiguo</strong>
        <div class=" card-header-actions">
          <form class="form-inline">
            <b>Filas por p&aacute;gina</b>
            <select class="form-control mr-2 ml-1" @change="sizeChangeAntiguo($event)"
                    v-model="filasPorPaginaAntiguo">
              <option v-for="option in sizeoptionsAntiguo" :value="option.value">
                {{ option.text }}
              </option>
            </select>
            <button class="btn btn-outline-dark mr-2" type="button"
                    @click="borrarFiltroAntiguoBtnOnClick()">
              <font-awesome-icon icon="fa-sort-amount-up-alt"/>
              Quitar Filtro
            </button>
            <button class="btn btn-outline-success" data-html="true"
                    data-toggle="tooltip"
                    title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                    type="button"
                    @click="excelBtnOnClickAntiguo()">
              <font-awesome-icon icon="fa-file-excel"/>
              Exportar a Excel
            </button>
          </form>
        </div>
      </div>
      <div class="card-body p-0 pe-1 pr-1">
        <JqxGrid :width="'100%'" ref="jqxgridAntiguo"
                 :source="dataAdapterAntiguo"
                 :autoheight="true" :autorowheight="false" :rowsheight="50"
                 :pageable="true"
                 :pagesize="parseInt(filasPorPagina)"
                 :virtualmode="true"
                 :rendergridrows="rendergridrowsAntiguo"
                 :columns="columnsAntiguo" :enablebrowserselection="true"
                 :enabletooltips="true" :filterable="true"
                 :showfilterrow="true"
                 :sortable="true"
                 :pagermode="'simple'" :localization="localization"
                 :theme="'bootstrap'" :columnsresize="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var sourceLstAntiguo;

var modalPdf;
var pagesizeoptions = [3, 6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'EditUser',
  components: {
    Alerts,
    JqxGrid,
    jqxInput
  },
  props: {
    codigo_alumno:Number,
  },
  data: () => {
    return {
      filasPorPagina: pagesizeoptions[0],
      filasPorPaginaAntiguo: pagesizeoptions[0],
      sizeoptions: [],
      sizeoptionsAntiguo: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      dataAdapterAntiguo: new jqx.dataAdapter(sourceLstAntiguo, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      rendergridrowsAntiguo: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Monto', datafield: "Monto", width: '6%',cellsalign:'right',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Monto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Fech_Venta', datafield: "Fech_Venta", width: '7%',cellsalign:'center',
          cellsformat: 'yyyy-MM-dd HH:mm:ss', filtertype: 'range',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Fecha<br />factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Concepto', datafield: "Concepto", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Concepto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NroFactura', datafield: "NroFactura", width: '6%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nro<br/>Factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Gestion', datafield: "Gestion", width: '7%',cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Obs', datafield: "Obs", width: '15%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Estado', datafield: "Estado", width: '8%',cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Cajero', datafield: "Cajero", width: '11%',cellsalign:'left',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NitCiComprador', datafield: "NitCiComprador", width: '7%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">NIT/CI<br/>Comprador</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NomComprador', datafield: "NomComprador", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nombre<br/>Comprados</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NroDeposito', datafield: "NroDeposito", width: '7%',cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
      ],
      columnsAntiguo: [
        {
          text: 'Monto', datafield: "Monto", width: '6%',cellsalign:'right',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Monto</div>';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div class="pt-1 text-center">'+(rowdata.Estado!='E'?'<strong class="text-danger">'+value+'</strong>':value)+'</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Fech_Venta', datafield: "Fech_Venta", width: '7%',cellsalign:'center',
          cellsformat: 'yyyy-MM-dd HH:mm:ss', filtertype: 'range',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Fecha<br />factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Concepto', datafield: "Concepto", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Concepto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NroFactura', datafield: "NroFactura", width: '6%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nro<br/>Factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Gestion', datafield: "Gestion", width: '7%',cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Observación', datafield: "Obs", width: '15%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<small class="pt-1 ps-1 pl-1 text-center">'+value+'</small>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Estado', datafield: "Estado", width: '8%',cellsalign:'center',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div class="pt-1 text-center">'+(value=='AN'?'<strong class="text-danger">'+value+'</strong>':value)+'</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Cajero', datafield: "Cajero", width: '11%',cellsalign:'left',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NitCiComprador', datafield: "NitCiComprador", width: '7%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">NIT/CI<br/>Comprador</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NomComprador', datafield: "NomComprador", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nombre<br/>Comprados</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'NroDeposito', datafield: "NroDeposito", width: '7%',cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  created() {
    try {
      let url = this.$apiAdress + '/api/pagos/' + this.codigo_alumno + '/facturaenlinea?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada
      let urlAntiguo = this.$apiAdress + '/api/pagos/' + this.codigo_alumno + '/facturasantiguas?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada
      sourceLst.url = url
      sourceLstAntiguo.url = urlAntiguo
      console.log(url)
      this.$refs.gridSystem.updatebounddata("filter");
      this.$refs.jqxgridAntiguo.updatebounddata()
    }catch (e){}
  },
  beforeCreate: function () {
    let url = ''
    let urlAntiguo = ''
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'Monto', type: "float"},
        {name: 'TipoM', type: "string"},
        {name: 'Fech_Venta', type: "date"},
        {name: 'producto_id', type: "int"},
        {name: 'Concepto', type: "string"},
        {name: 'Gestion', type: "string"},
        {name: 'Obs', type: "string"},
        {name: 'Estado', type: "string"},
        {name: 'Cajero', type: "string"},
        {name: 'NitCiComprador', type: "string"},
        {name: 'NomComprador', type: "string"},
        {name: 'NroFactura', type: "int"},
        {name: 'cuf', type: "int"},
        {name: 'codigoRecepcion', type: "string"},
        {name: 'FechaDeRegistro', type: "date"},
        {name: 'NroDeposito', type: "string"},
        {name: 'anio', type: "int"},
      ],
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'anio',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
    sourceLstAntiguo = {
      url: urlAntiguo,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'Monto', type: "float"},
        {name: 'TipoM', type: "string"},
        {name: 'Fech_Venta', type: "date"},
        {name: 'producto_id', type: "int"},
        {name: 'Concepto', type: "string"},
        {name: 'Gestion', type: "string"},
        {name: 'Obs', type: "string"},
        {name: 'Estado', type: "string"},
        {name: 'Cajero', type: "string"},
        {name: 'NitCiComprador', type: "string"},
        {name: 'NomComprador', type: "string"},
        {name: 'NroFactura', type: "int"},
        {name: 'NroAutoriza', type: "int"},
        {name: 'CodControl', type: "string"},
        {name: 'FechaDeRegistro', type: "date"},
        {name: 'NroDeposito', type: "string"},
        {name: 'anio', type: "int"},
      ],
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'anio',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLstAntiguo.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptionsAntiguo = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptionsAntiguo[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptionsAntiguo[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.jqxgridAntiguo.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.jqxgridAntiguo.updatebounddata("sort");
      },
    };
  },
  methods: {
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    borrarFiltroAntiguoBtnOnClick: function () {
      this.$refs.jqxgridAntiguo.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {
      }
    },
    sizeChangeAntiguo: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.jqxgridAntiguo.pagesize = parseInt(this.filasPorPaginaAntiguo);
        this.$refs.jqxgridAntiguo.updatebounddata();
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'FacturasEnLinea_' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
    },
    excelBtnOnClickAntiguo: function () {
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.jqxgridAntiguo.exportdata('xls', 'FacturasSistemaAntiguo_' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
    },
  },
  mounted: function () {
    modalPdf = this;
  }
}
</script>