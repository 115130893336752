<template>
  <div>
    <hr class="m-0 "/>
    <div class="row">
      <div class="col-3 border-right">
        <label>1er Apellido</label>
        <input v-model="inscripcion.apellido1" class="form-control-plaintext h5" readonly="" type="text"/>
      </div>
      <div class="col-3 border-right">
        <label>2do Apellido</label>
        <input v-model="inscripcion.apellido2" class="form-control-plaintext h5" readonly="" type="text"/>
      </div>
      <div class="col-3 border-right">
        <label>Nombre(s)</label>
        <input v-model="inscripcion.nombres" class="form-control-plaintext h5" readonly="" type="text"/>
      </div>
      <div class="col-3">
        <label>Código de alumno</label>
        <input v-model="inscripcion.codigo_alumno" class="form-control-plaintext h5" readonly="" type="text"
               required="required"/>
      </div>
    </div>
    <hr class="m-0 "/>
    <div class="row">
      <div class="col-4 border-right">
        <label for="Facultad">Facultad</label>
        <select v-if="inscripcion.facultad_id" id="Facultad" style="color: #000000;"
                v-model="inscripcion.facultad_id" class="form-control-plaintext h5" disabled="">
          <option v-for="option in facultades" :value="option.value">{{ option.label }}
          </option>
        </select>
      </div>
      <div class="col-4 border-right">
        <label for="alumnoCarrera">Carrera</label>
        <select v-if="inscripcion.facultad_id" id="alumnoCarrera" style="color: #000000;"
                  v-model="inscripcion.carrera_id" class="form-control-plaintext h5" disabled="">
          <option v-for="option in carreras[inscripcion.facultad_id]" :value="option.value">{{ option.label }}
          </option>
        </select>
      </div>
      <div class="col-2 border-right">
        <label for="Nacionalidad">Nacionalidad</label>
        <select v-if="inscripcion.nacionalidad_id" id="Nacionalidad" style="color: #000000;"
                v-model="inscripcion.nacionalidad_id" class="form-control-plaintext h5" disabled="">
          <option v-for="option in nacionalidades" :value="option.value">{{ option.label }}
          </option>
        </select>
      </div>
      <div class="col-2">
        <label>Gestión de ingreso</label>
        <input v-model="inscripcion.gestion" readonly="" type="text" class="form-control-plaintext h5"/>
      </div>
    </div>
    <hr class="mt-0 mb-3"/>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Alerts from "@/views/notifications/Alerts";

var modalPdf;

export default {
  name: 'frmdatosestudiante',
  components: {
  },
  props: {
    inscripcion: Object,
    facultades: Array,
    carreras: Object,
    nacionalidades:Array,
  },
  data: () => {
    return {

    }
  },
  mounted: function () {
    modalPdf = this;
  }
}
</script>