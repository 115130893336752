<template>
  <CModal
      :centered="true"
      :closeOnBackdrop="false"
      :fade="true"
      :no-close-on-backdrop="true"
      :show.sync="darkModal"
  >
      <div class="row">
        <div class="col-12 pb-3 pt-2">
          <h5 class="modal-title pb-2">
            <span v-html="mensaje"></span>
          </h5>
          <span v-html="mensaje2"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <CButton @click="continuar" class="btn btn-success">Continuar</CButton>
          <CButton @click="cancelar" class="btn btn-outline-dark ml-3">Cancelar</CButton>
        </div>
      </div>
    <template #header-wrapper><span></span></template>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>

export default {
  name: 'Confirm',
  props: {

  },
  data() {
    return {
      darkModal: false,
      mensaje: '',
      mensaje2: '',
      method: {type: Function},
      methodCancel: {type: Function},
    }
  },
  methods: {
    confirm(mensaje,mensaje2,funcion=null,methodCancel=null) {
      this.mensaje = mensaje
      this.mensaje2 = mensaje2
      if ( funcion != null ) {
        this.method = funcion
      }
      if ( methodCancel != null ) {
        this.methodCancel = methodCancel
      }
      this.darkModal = true
    },
    continuar(){
      this.method()
      this.mensaje = ''
      this.mensaje2 = ''
      this.darkModal = false
    },
    cancelar(){
      try {
        if (typeof this.methodCancel === 'function') {
          this.methodCancel()
        }
      }catch (e) {
        
      }
      this.mensaje = ''
      this.mensaje2 = ''
      this.darkModal = false
    }
  }
}
</script>